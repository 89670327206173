import request from '@/utils/request'

// 查询岗位信息
export function getSelectZqRecruit(data) {
  return request({
    url: '/recruit/selectZqRecruit',
    method: 'post',
    data
  })
}

// 联系我们
export function addZqContactUsInfo(data) {
  return request({
    url: '/contactUs/addZqContactUsInfo',
    method: 'post',
    data
  })
}

// 查询联系我们信息
export function getZqContactUsSet(data) {
  return request({
    url: `/contactUs/getZqContactUsSet`,
    method: 'post',
    data,
  })
}


