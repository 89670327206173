<template>
  <div>
    <div class="contact-box flex-between usually-box">
      <div class="msg-box">
        <h3>业务咨询请拨打</h3>
        <h3 style="margin-bottom: 30px; user-select: all;">{{contactMsg.contactNum}}</h3>
        <p>公司地址：{{contactMsg.address}}</p>
        <p>联系电话：{{contactMsg.contactNum}}</p>
        <p>合作洽谈：{{contactMsg.cooperationInfo}}</p>
        <p>技术支持：{{contactMsg.technologyInfo}}</p>
        <p>邮　　箱：{{contactMsg.email}}</p>
      </div>
      <el-card>
        <div class="form-box">
          <el-form label-position="left" ref="formRef" label-width="100px" :model="form" :rules="formRules" style="max-width: 460px">
            <el-form-item label="联系人:" prop="name">
              <el-input v-model.trim="form.name" />
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model.trim="form.mobile" />
            </el-form-item>
            <el-form-item label="单位名称" prop="companyName">
              <el-input v-model.trim="form.companyName" />
            </el-form-item>
            <el-form-item label="咨询问题" prop="content">
              <el-input type="textarea" v-model.trim="form.content" />
            </el-form-item>
            <el-checkbox v-model="isAgree">我已阅读并同意<span @click="showAgreement = true">《用户授权协议》</span></el-checkbox>
            <el-form-item style="margin-top: 20px">
              <el-button type="primary" style="font-size: 20px; height: 44px;  padding: 5px 50px;" @click.native="formSubmit()">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
    <div class="local usually-box">
      <h3>公司地址</h3>
      <div id="map" style="height: 500px; zoom: 1">

      </div>
    </div>
    <el-dialog title="用户隐私协议" v-model="showAgreement" width="30%">
      <div class="agree-content">
        <p>1.同意即代表您同意《知谦网络科技有限公司》获知您的联系方式，姓名等个人信息</p>
        <p>2.《知谦网络科技有限公司》将为您保证联系方式不泄漏给第三方</p>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, computed, toRefs, nextTick } from 'vue'
import { onMounted } from 'vue'
import { baiduMap } from '@/utils/baiduMap'
import { ElMessage, ElMessageBox } from 'element-plus'
import { addZqContactUsInfo, getZqContactUsSet } from '@/api/post'


function checkMobile(rule, value, callback) {
  if (value === '') {
    callback(new Error('联系方式不能为空'))
  } else if (!/^1\d{10}$/.test(value)) {
    callback(new Error('联系方式格式错误'))
  } else {
    callback()
  }
}
const contactMsg = ref({})
getZqContactUsSet().then(res => {
  contactMsg.value = res.data;
  nextTick(() => {
    createBaiduMap('长沙知谦网络科技有限公司', res.data.coordinate)
  })
})

let formRef = ref({})
const formData = reactive({
  isAgree: true,
  isClick: false,
  form: {
    companyName: '',
    content: '',
    mobile: '',
    name: '',
  },
  formRules: {
    name: [
      { required: true, message: '联系人姓名不能为空', trigger: 'blur' },
      { max: 100, message: '请输入正确的联系人姓名', trigger: 'blur' },
    ],
    companyName: [
      { required: true, message: '单位名称不能为空', trigger: 'blur' },
      { max: 100, message: '请输入正确的单位名称', trigger: 'blur' },
    ],
    mobile: [
      { required: true, validator: checkMobile, trigger: 'blur' }
    ],
    // content: [
    //   { required: true, message: '咨询问题不能为空', trigger: 'blur' },
    //   { min: 10, message: '咨询问题不能小于10个字', trigger: 'blur' },
    // ],
  },
  formSubmit() {
    if (this.isClick) {
      ElMessage({
        message: '信息已提交，我们将尽快联系您',
        type: 'success',
      })
      return false
    }
    formRef.value.validate((valid) => {
      if (valid) {
        addZqContactUsInfo(this.form).then(res => {
          ElMessageBox.alert('信息已提交', '提示', {
            confirmButtonText: '确定'
          })
          this.isClick = true
        })
      } else {
        return false
      }
    })
    // let data = addZqContactUsInfo(this.form)

    // console.log(data)
    // console.log('提交')
  }
})

const { isAgree, form, formRules, formSubmit } = toRefs(formData)
const showAgreement = ref(false)

function createBaiduMap(name, markAddress) {
  baiduMap('rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR').then((mymap) => {
    // 创建地图实例
    const newAddress = markAddress.split(',')
    let map = new BMap.Map('map');
    let point = new BMap.Point(Number(newAddress[0]), Number(newAddress[1]));
    let marker = new BMap.Marker(point)
    map.centerAndZoom(point, 17); //设置缩放级别  
    map.enableScrollWheelZoom()
    let infoWindow = new BMap.InfoWindow(name, {
      position: point,
      width: 25,
      height: 5,
      title: '地址'
    })
    map.openInfoWindow(infoWindow, point)
  });
}
let show = ref(false)
</script>
<style lang='scss' scoped>
.contact-box {
  // margin-top: $headerHeight;
  padding-top: $headerHeight + 100px;
  padding-bottom: 80px;
  font-size: 20px;
  color: #fff;
  // background-color: #192036;
  // background-color: rgb(0, 177, 150);
  background-color: $contactColor;
  .msg-box {
    > h3 {
      font-size: 28px;
      color: $hoverColor;
      line-height: 1.7em;
    }
    > p {
      font-size: 18px;
      line-height: 2em;
      opacity: 0.9;
    }
  }
  .form-box {
    width: 400px;
  }
}
.local {
  margin: 50px 0;
  > h3 {
    padding: 50px 0;
    font-size: 26px;
    text-align: center;
  }
}
.agree-content {
  > p {
    margin-bottom: 10px;
  }
}
</style>